import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup  } from 'react-bootstrap';
import Loading from "../components/Loading";
import cclogo from '../images/Care_Coach_Logo_inline_400x267.jpg';
import { FluentCard } from '../components/FluentComponents';
import { microsoftLoginWithOrg } from "../actions/accountActions";
import { useNavigate } from "react-router-dom";
const orgListComponents = () => {
  const dispatch = useDispatch();  // get logged in user from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo, subOrgList } = userLogin;
  const navigate = useNavigate()
  
  // home screen route
  const home = "/updates";

  useEffect(() => {
    if (userInfo) {
      navigate(home);
    }
  }, [userInfo]);
  

  const handleChooseOrg = async (orgObj) => {
    if (orgObj) {
      dispatch(microsoftLoginWithOrg(orgObj));
    }
  };
  return (
    <div
      className="text-center justify-center .item-center logincontainer"
      style={{ backgroundColor: loading ? "white" : "rgb(77, 77, 77)" }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col
            style={{
              marginTop: "30vh",
            }}
          >
            <FluentCard className="fluent-card" style={{height:"auto",paddingBottom:"20px",maxHeight:"50%"}}>
            <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                <strong style={{fontSize:"20px"}}
                >Select Organization</strong>
              </div>
              <ListGroup style={{ height :"auto",overflow: 'auto'}}>
                {subOrgList ? (
                      subOrgList.map((org) => (
                        <ListGroup.Item
                            key={org.id}
                            action
                            onClick={() => handleChooseOrg(org)}
                            style={{ lineHeight: 2}}
                            className="custom-list-item"
                        >
                          {org.name}
                        </ListGroup.Item>
                      ))
                  ) : (
                    <div style={{width:"300px", wordWrap:"break-word", placeSelf:"center", marginTop:""}}>
                      ...
                    </div>
                  )}
              </ListGroup>
            
            </FluentCard>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default orgListComponents;
