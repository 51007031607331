import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import {HomeRegular,LinkRegular,ChatHelpRegular,FlagRegular,PanelRightExpandRegular,PanelLeftExpandRegular} from '@fluentui/react-icons'
import { TOGGLE_SIDEBAR } from "../constants/accountConstants";
function SideBar(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const sideBarGlobalState = useSelector(state => state.sidebar)
  const {toggled} = sideBarGlobalState
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin;
  const handleToggleSidebar = () => {
    dispatch({
      type : TOGGLE_SIDEBAR,
      payload: !toggled
    })
  }
  return (
    <Sidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          background: "#FFF",
          minHeight: "100vh",
          position:"fixed",
          width:toggled ? "80px" : "250px"
        },
      }}
      data-testid="sidebar"
      collapsed={toggled}
      collapsedWidth="80px"
      width={toggled ? "80px" : "250px"}
    >
      <Menu
        style={{marginTop:"1.5rem",}}
        menuItemStyles={{
          button: ({ level }) => {
            if (level === 0) {
              return {
                color: "#366ccc",
                textAlign: "center",
                fontSize: "1.0rem",
                fontWeight: "600",
                lineHeight: "1.5rem",
                padding: "1.0rem",
                
                
                "&:hover": {
                  color: "#7EC8E3 !important",
                  fontSize: "1.0rem",
                  fontWeight: "600",
                  lineHeight: "1.25rem",
                },
              };
            }
          },
        }}
      >
        <MenuItem
          className="toggleBtn"
          onClick={handleToggleSidebar}
          icon={toggled ? <PanelLeftExpandRegular style={{fontSize:"30px", color:"grey"}} onClick={handleToggleSidebar}  /> : <PanelRightExpandRegular style={{fontSize:"30px", color:"grey"}} onClick={handleToggleSidebar} />}
        >
        </MenuItem>
        
        {userInfo && userInfo.features.map((feature, i) => {
          const count = userInfo.features.filter((f, index) => index < i && f.feature.name === feature.feature.name).length;
          const displayName = count > 0 ? `${feature.feature.name} ${feature.abbreviation}` : feature.feature.name;
          
          return (
            <MenuItem
            className={`text-center ${location.pathname == feature.config.redirectTo && "activeMenu"}`}
              key={i}
              // Check if feature.config is not null and having redirectTo key with it
              component={feature.config && isValidJSON(feature.config) ? (feature.config.redirectTo ? <Link to={feature.config.redirectTo} /> : null) : null}
            >
              <span className="item-center justify-center">
              {/* this check is need to be done for the icon string will empty  */}
                {/* {feature.feature.icon != "" && <img src={feature.feature.icon} alt="Icon" className="icon sidemenu-icon-name item-margin-right self-align-flex-start" 
                  //if url gets faulty in any case the icon doesnt gets loaded img tag will not be display by this
                  onError={(e) => { e.target.style.display = 'none'; }} 
                />
                } */}
                <img style={{width:"30px"}} src={feature.feature.icon} className='icon sidemenu-icon-name item-margin-right self-align-flex-start'  { ...( toggled ? { title: feature.feature.name } : {} ) }></img>
                {/* <FlagRegular style={{fontSize:"30px"}} className='icon sidemenu-icon-name item-margin-right self-align-flex-start'/> */}
                {!toggled &&<span className="flex-child initial">
                  {feature.feature.name}
                </span>}
              </span>
            </MenuItem>
          );
        })}
        
        
      </Menu>
    </Sidebar>
  );
}

// Function to check if a feature.config is JSON having the redirectTo key
function isValidJSON(config) {
  try {
    // Check if redirectTo exists and is not an empty string
    if (config.redirectTo && config.redirectTo !== "") {
      return true;
    }
    return false;
  } catch (e) {
    console.error(e)
    return false;
  }
}
export default SideBar;
