// Authentication service to handle login, logout and token generation for msal
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

// initialize PublicClientApp with msal config
const pca = new PublicClientApplication(msalConfig);

// get active account if interaction is ongoing
const activeAccount = pca.getActiveAccount();
const isInteractionInProgress =
  activeAccount && activeAccount.interactionInProgress;

/**
 * Initiates the login process.
 *
 * @returns {Promise<Account | null>} A Promise that resolves with the logged-in account, or null if login fails.
 */
const login = async () => {
  await pca.initialize();
  if (!isInteractionInProgress) {
    try {
      const loginResponse = await pca.loginPopup(loginRequest);
      return loginResponse.account;
    } catch (error) {
      console.error(error);
      return null;
    }
  } else {
    console.warn(
      "Interaction is already in progress. Wait for it to complete." 
    );
  }
};

/**
 * Initiates the logout process.
 */
const logout = () => {
  if (!isInteractionInProgress) {
    pca.logoutPopup();
  } else {
    console.warn(
      "Interaction is already in progress. Wait for it to complete."
    );
  }
};

/**
 * Retrieves the access token.
 *
 * @returns {Promise<string | null>} A Promise that resolves with the access token, or null if retrieval fails.
 */
const getToken = async () => {
  await pca.initialize();
  const accounts = pca.getAllAccounts();

  if (accounts.length === 0) {
    return null;
  }

  const silentRequest = {
    account: accounts[0],
    scopes: loginRequest.scopes,
  };

  try {
    const response = await pca.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { login, logout, getToken };
