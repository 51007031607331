// This module is the main component of this React application.
// It contains the structure and logic for your app's user interface.
// You can define the layout, components, and functionality within this file.
 
import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "./components/NavBar";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import IdleHandler from './helpers/IdleHandler';
import RenderScreen from "./screens/FrameScreen"
import { MICROSOFT_LOGIN_SUCCESS } from "./constants/accountConstants";
import { useEffect } from "react";
import OrgList from "./screens/orgListComponents";
function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin;
  var isAuth = true
  let timeout = 0;
  if (userInfo) {
    timeout = 1000 * 60 * parseInt(userInfo.session.idle_time); // In minutes
  }
 
if(location.pathname != "/"){
    if(!userInfo){
      userInfo = JSON.parse(localStorage.getItem('user'))
      if(!userInfo ){
        isAuth = false
      }else{
        dispatch({
          type : MICROSOFT_LOGIN_SUCCESS,
          payload : userInfo
        })
        isAuth = true
        navigate(location.pathname)
      }
 
    }
}
  useEffect(()=>{
    if (!isAuth){
      navigate('/')
    }
  },[])
 
  return (
    <>
      {userInfo ? <IdleHandler timeout={timeout} /> : null}
      <div className="App">
        {userInfo ? <NavBar /> : null}
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="*" element={<RenderScreen/>} />
          <Route path="/select_org" element={<OrgList />} />
        </Routes>
      </div>
    </>
  );
}
export default App;