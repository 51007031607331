/**
 * Report Screen
 * Component for Report Page
 *
 * Pulls up HTML to display on Report ,feature & it's configuration picked up from localStorage
 */
import React,{useEffect} from "react";
import SideBar from "../components/SideBar";
import { getLocalStream, revokePermissions } from './permissions';

function IframeScreenComponent(props) {
const embedCode=props.embedCode
const name=props.name
const msg=props.notFoundMsg
// name to be render``

useEffect(() => {
  if(props.name.toLowerCase().includes('visit')){
    getLocalStream();
  }else{
    revokePermissions()
  }
}, [name])

  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100%" }} className="reportsMain">
        <div className="reportsContainer">
          <div className="flex-container">
            <div className="flex-child" style={{margin: "1px 1px 1px 2px"}}>
              {/* <h1
                className="page-title"
                style={{
                  marginLeft: "4%",
                  color: "#366ccc",
                  fontWeight: "800",
                  marginTop: "1%",
                  paddingBottom: "0.5rem",
                  fontSize: "3rem",
                  width: "95%",
                  textAlign: "left",
                }}
              >
                {name}

              </h1>
              <hr /> */}
              {/* checking for the embedCode that its empty or not */}
              {embedCode ? (
                <div  className="width-full flex" style={{flexDirection:"column",overflow: "hidden"}}>
                  {Array.isArray(embedCode) ? (
                      embedCode.map((code, index) => (
                        <div className='frameinside'  >
                        <iframe
                          className='iFrame'
                          key={index}
                          src={code}
                          width="100%"
                          // height="auto"
                          frameBorder="0"
                          allowFullScreen
                          allow="camera; microphone;"
                          style={{ height: '100%', maxHeight: '100vh',borderRadius: "10px 10px 10px 10px"  }}
                        ></iframe>
                        <hr/>
                        </div>
                      ))
                    ) : (
                      <div className='frameinside'  >
                      <iframe id="iframe"
                        src={embedCode}
                        width="100%"
                        frameBorder="0"
                        allowFullScreen
                        allow="camera; microphone"
                        style={{ height: '100%', maxHeight: '100vh',borderRadius: "10px 10px 10px 10px" }}
                      ></iframe>
                      </div>
                    )}

                </div>
              ) : (
                <div className="item-center justify-center">
                  {/* Display error message when the embedcode not found */}
                  <h3 style={{color:"grey", opacity:"0.6"}}>
                    {msg}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default IframeScreenComponent;
