/**
 *  Report,Video Visit,Enrollment Screen,
 * Component for Report,Video Visit,Enrollment
 *
 * Pulls up HTML to display on Report ,feature & it's configuration picked up from localStorage
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import IframeScreenComponent from "./iframecomponent";

function IframeScreen() {
  const location = useLocation()
  const [embedCode, setEmbedCode] = useState("");
  const [name, setName] = useState("")
  // user state
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo
  const user = userInfo; //get user from global state
  const msg = "Can't get the details at the moment. Please try again after some time."
  //this function is used to get the embedcode //docker-compose up --build -d
  const GetEmbedCode = () => {

    try {
      user.features.forEach((entry) => {
        if (entry.feature.config !== null) {
          if (location.pathname == entry.config.redirectTo) {
            // if (entry.config.redirectTo == "/enrollment") {
            if (entry.feature.name !== "") {
              setName(entry.feature.name)
            }
            setEmbedCode(entry.config.embedCode);
          }
        }
      });
    } catch {
      setEmbedCode("");
    }
  };

  // Run only once on component mount
  useEffect(() => {
    GetEmbedCode();
  }, [location.pathname]);
  return (
    <>
      <IframeScreenComponent name={name} embedCode={embedCode} notFoundMsg={msg} />
    </>
  )
}

export default IframeScreen;
