/**
 * Component for Updates Page
 *
 * Pulls up HTML to display from update spotlight feature & it's configuration picked up from localStorage
 * and from API endpoint to display announcements
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpdateData } from "../actions/updateActions";
import SideBar from "../components/SideBar";
import DOMPurify from "dompurify";
import {useNavigate} from 'react-router-dom'
function UpdateScreen(props) {
  const [featureData, setFeatureData] = useState("");
  const [isFeaturedDataArray, setIsFeaturedDataArray] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const { updateData } = useSelector((state) => state.updates);

  /**
   * Call API endpoint action on component render
   */
  useEffect(() => {
    dispatch(fetchUpdateData());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo) {
          navigate("/"); // navigate to login
        }else{
          try {
            if (props.entry.config && props.entry.config.displayHtml) {
              setFeatureData(props.entry.config.displayHtml);
            } else {
              setFeatureData(null);
            }
          } catch (error) {
            console.error("Error:", error);
            setFeatureData(null);
          }
        }
  }, [props.entry]);
  /**
   * Read from localStorage the feature config and sets to localState
   * Empty array to run it once when component renders
   */
  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate("/"); // navigate to login
  //   } else {
  //     try {
  //       let data = userInfo;
  //       data.features.forEach((entry) => {
  //         if (entry.config.redirectTo === "/updates") {
  //           setFeatureData(entry.config.displayHtml);
  //         }
  //       });
  //     } catch {
  //       setFeatureData(null);
  //     }
  //   }
  // }, []);

  /**
   * Date parsing format config
   */
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  /**
   *  userInfo from globalState
   */
  const user = userInfo;

  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100vw" }} className="updateMain">
        <div className="updateContainer">
          <div className="flex-container">
            <div className="flex-child">
              <h1
                className="page-title"
                style={{
                  marginLeft: "4%",
                  color: "#366ccc",
                  fontWeight: "800",
                  marginTop: "1%",
                  paddingBottom: "0.5rem",
                  fontSize: "2rem",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Updates
              </h1>
              <hr />
              {updateData.length > 0 ? (
                updateData.map((update) => (
                  <div key={update.uuid}>
                    <div
                      className="left updateChild"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginLeft: "4%",
                        padding: "30px 15px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <h4
                        style={{
                          marginRight: "10px",
                          minWidth: "160px",
                          maxWidth:"160px",
                          fontWeight: "bolder",
                        }}
                      >
                        {new Date(update.created).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </h4>
                      <div style={{ maxWidth: "640px" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: update.changelog_html,
                          }}
                        />
                      </div>
                    </div>
                    <hr style={{ opacity: "0.10" }} />
                  </div>
                ))
              ) : (
                <p>No announcements available</p>
              )}
            </div>
          </div>
        </div>
        {
          <div className="featureContainer">
            <div className="flex-container">
              <div className="flex-child">
                <h1
                  className="page-title"
                  style={{
                    marginLeft: "4%",
                    color: "#366ccc",
                    fontWeight: "900",
                    marginTop: "1%",
                    paddingBottom: "0.5rem",
                    fontSize: "2rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {featureData.title ? featureData.title : "Feature Spotlight"}
                </h1>
                <hr />
                {featureData && !Array.isArray(featureData.html) ? (
                  <>
                    {/* <h2 className="page-title">{mockFeatureSpotlight.title}</h2> */}
                    <div
                      className="update left updateChild, featureImage"
                      style={{
                        // display: "flex",
                        marginLeft: "4%",
                        padding: "0px 15px",
                      }}
                    >
                      <>
                      {featureData.image && featureData.image !== "" && (
                        <div>
                          <img
                              src={featureData.image}
                          />
                        </div>
                      )}

                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(featureData.html),
                          }}
                        />
                      </>
                    </div>
                    <hr style={{ opacity: "0.10" }} />
                  </>
                ) : // featured data when its an array
                Array.isArray(featureData.html) && featureData.html.length > 0 ? (
                  featureData.html.map((data, index) => (
                    <>
                      {/* <h2 className="page-title">{mockFeatureSpotlight.title}</h2> */}
                      <div
                        className="update left updateChild featureImage"
                        style={{
                          display: "flex",
                          marginLeft: "4%",
                          padding: "30px 15px",
                        }}
                      >
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(data),
                            }}
                          />
                        </>
                      </div>
                      <hr style={{ opacity: "0.10" }} />
                    </>
                  ))
                ) : (
                  <h3 style={{color:"grey", opacity:"0.6"}}>No spotlights available at the moment!</h3>
                )}
              </div>
            </div>
          </div>
        }
      </main>
    </div>
  );
}

export default UpdateScreen;
