// Configurations for msal(Microsoft authentication library for JavaScript)

/**
 * Configuration object for Microsoft Authentication Library (MSAL).
 */
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/common`, /*${process.env.REACT_APP_MICROSOFT_TENANT_ID}*/
    redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

/**
 * Request object for MSAL login.
 */
const loginRequest = {
  scopes: ["openid", "profile", "email"]
};

/**
 * Definition of protected resources for Microsoft Graph API.
 */
const protectedResources = {
  graphAPI: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
};

export { msalConfig, loginRequest, protectedResources };
