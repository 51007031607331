// This module contains reducers for dispatched account actions

import {
  MICROSOFT_LOGIN_REQUEST,
  MICROSOFT_LOGIN_SUCCESS,
  MICROSOFT_LOGIN_FAIL,
  LOGOUT_SUCCESS,
  TOGGLE_SIDEBAR,
  NAVIGATE_TO_LIST_ORG_LIST
} from "../constants/accountConstants";

/**
 * Reducer function for handling user login actions.
 *
 * @param {object} state - The current state.
 * @param {object} action - The action object.
 * @returns {object} The new state.
 */
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case MICROSOFT_LOGIN_REQUEST:
      return { loading: true };

    case MICROSOFT_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    
    case NAVIGATE_TO_LIST_ORG_LIST:
      return { loading: false, subOrgList: action.payload };

    case MICROSOFT_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case LOGOUT_SUCCESS:
      return {};

    default:
      return state;
  }
};

export const toggleSidebarReducer = (state = {toggled:true}, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {toggled: action.payload}
    default:
      return state;
  }
}