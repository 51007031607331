// Login Screen

import { React, useEffect } from "react";
import { login } from "../helpers/authService";
import MicrosoftLoginButton from "../components/MicrosoftLoginButton";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { microsoftLogin } from "../actions/accountActions";
import Loading from "../components/Loading";
import { FluentCard } from "../components/FluentComponents";
import cclogo from "../images/Care_Coach_Logo_inline_400x267.jpg";
function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // home screen route
  const home = "/updates";

  // get logged in user from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo, subOrgList } = userLogin;
  useEffect(() => {
    if (userInfo) {
      navigate(home);
    }
  }, [userInfo, navigate]);

  useEffect(()=>{
    if(subOrgList){
      navigate('/select_org')
    }
  },[subOrgList])
  // Login to Microsoft, get ID token and dispatch to API
  const handleLogin = async () => {
    const account = await login();
    if (account) {
      dispatch(microsoftLogin(account.idToken));
    }
  };

  return (
    <div
      className="text-center justify-center .item-center logincontainer"
      style={{ backgroundColor: loading ? "white" : "rgb(77, 77, 77)" }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col
            style={{
              marginTop: "30vh",
            }}
          >
            <FluentCard className="fluent-card">
              <div class="containerLogo">
                <img
                  src={cclogo}
                  alt="care-coach logo"
                  style={{ width: "250px" }}
                />
                <div class="text-overlay">
                  <h4>Portal</h4>
                </div>
              </div>
              <MicrosoftLoginButton handleLogin={handleLogin} />
              <div style={{width:"300px", wordWrap:"break-word", placeSelf:"center", marginTop:""}}>
               {error && <p  style={{color:"red", width:"300px"}}>{error}</p>}
              </div>
            </FluentCard>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default LoginScreen;