// IdleHandler.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/accountActions";

/**
 * Custom hook to track idle time.
 *
 * @param {number} timeout - Timeout duration in milliseconds.
 * @param {function} onIdle - Callback function to execute when idle.
 */

const useIdleTimer = (timeout, onIdle) => {
  useEffect(() => {
    let timer = setTimeout(onIdle, timeout);

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(onIdle, timeout);
    };

    const events = ["mousemove", "keydown", "scroll"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    return () => {
      clearTimeout(timer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, [timeout, onIdle]);
};

/**
 * Component to handle user idle behavior.
 *
 * @param {number} timeout - The idle timeout duration in milliseconds.
 */
export default function IdleHandler({ timeout }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const callback = (response) => {
    if (response && response.status === 200) {
      navigate("/");
    }
  };

  const handleIdle = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch(logout(callback));
    } else {
      console.log("No user session found. Redirecting to login.");
      navigate("/");
    }
  };

  useIdleTimer(timeout, handleIdle);
  return null;
}
