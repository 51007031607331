// This module contains redux store set up. Redux toolkit is used for the store.

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { toggleSidebarReducer, userLoginReducer } from "./reducers/accountReducers";
import { updateReducer } from "./reducers/updateReducers";
const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  updates: updateReducer,
  sidebar: toggleSidebarReducer,
});

const store = configureStore({ reducer: rootReducer });

export default store;
