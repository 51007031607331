// This module contains account related actions

import axios from "axios";
import {
  MICROSOFT_LOGIN_REQUEST,
  MICROSOFT_LOGIN_SUCCESS,
  MICROSOFT_LOGIN_FAIL,
  LOGOUT_SUCCESS,
  TOGGLE_SIDEBAR,
  NAVIGATE_TO_LIST_ORG_LIST
} from "../constants/accountConstants";

/**
 * Performs Microsoft login authentication.
 *
 * @param {string} token - The authentication token for Microsoft login.
 * @param {function} dispatch - The dispatch function provided by Redux.
 * @returns {user object}
 * @throws {MICROSOFT_LOGIN_FAIL} If authentication fails, an action of type MICROSOFT_LOGIN_FAIL is dispatched.
 */
export const microsoftLogin = (token) => async (dispatch) => {
  try {
    dispatch({
      type: MICROSOFT_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "none",
      },
    };

    const { data } = await axios.post(
      "/v1/accounts/microsoft_login/",
      {
        token: token,
      },
      config
    );

    // set authorization token to authenticated user token
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.token.access}`;
    //cheking for the multiple suborg available for that user 
    if(data.allorganization.length > 1){
      dispatch({ type: NAVIGATE_TO_LIST_ORG_LIST, payload:data.allorganization });
      //temprorly saving the data into local storage will modify feature after selection of suborg
      localStorage.setItem("user-temp", JSON.stringify(data));
    }else{
      //only single subrorg available
      dispatch({
        type: MICROSOFT_LOGIN_SUCCESS,
        payload: data,
      });
      // store user object in local storage
      localStorage.setItem("user", JSON.stringify(data));
    }
  } catch (error) {
    dispatch({
      type: MICROSOFT_LOGIN_FAIL,
      payload:
        error.response && error.response.data.non_field_errors
          ? error.response.data.non_field_errors[0]
          : error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



export const microsoftLoginWithOrg = (subOrgObject) => async (dispatch) => {
  try {

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "none",
      },
    };
    // set authorization token to authenticated user token
    let old_data=JSON.parse(localStorage.getItem("user-temp"));
    const { data } = await axios.post(
      "/v1/accounts/select_org_for_login/",
      {
        organization: subOrgObject.id,
        email:old_data.user.email,
      },
      config
    );
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.token.access}`;
    //temp localstorage data to be take out for updating the features of it
    //modifying the fetures available into that suborg/org
    old_data.features=data.features;
    old_data.token=data.token;
    // store user object in local storage
    localStorage.setItem("user", JSON.stringify(old_data));
    //removing the temp localstorage
    localStorage.removeItem("user-temp");
    dispatch({
      type: MICROSOFT_LOGIN_SUCCESS,
      payload: old_data,
    });

    } catch (error) {
    dispatch({
      type: MICROSOFT_LOGIN_FAIL,
      payload:
        error.response && error.response.data.non_field_errors
          ? error.response.data.non_field_errors[0]
          : error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/**
 * Performs logout action.
 *
 * @param {function} callback - Callback function to handle response.
 * @returns {function} Thunk function to handle async dispatch.
 */
export const logout = (callback) => async (dispatch) => {
  axios
    .post("/v1/accounts/microsoft_logout/")
    .then(function (response) {
      callback(response);
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch(function (error) {
      console.log("error::", error);
      callback(error);
    });
};
// performs sidebar toggling
export const toggleSideBar = (toggled) => (dispatch) => {
  dispatch({
      type : TOGGLE_SIDEBAR,
      payload : toggled
  })
}