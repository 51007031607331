/**
 * Component for Support Page
 *
 * Pulls up HTML to display from support feature & it's configuration picked up from localStorage
 */

import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import {  useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

function SupportScreen(props) {
  const navigate = useNavigate();
  const [supportData, setSupportData] = useState("");
  // user state
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo

  useEffect(() => {
    if (userInfo){
      try {
        if (props.entry.config && props.entry.config.displayHtml) {
          setSupportData(props.entry.config.displayHtml);
        } else {
          setSupportData(null);
        }
      } catch (error) {
        console.error("Error:", error);
        setSupportData(null);
      }
    }else{
      navigate("/")
    }

  }, [props.entry]);

  /**
   * Read from localStorage the feature config and sets to localState
   * Empty array to run it once when component renders
   */
  // useEffect(() => {
  //   try {
  //     let data = userInfo;
  //     data.features.forEach((entry) => {
  //       if (entry.config.redirectTo === "/support") {
  //         setSupportData(entry.config.displayHtml);
  //       }
  //     });
  //   } catch {
  //     setSupportData(null);
  //   }
  // }, []);

  /**
   * Date parsing format config
   */
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  /**
   * getting userInfo from global state and assiging to user
   */
  const user = userInfo;

  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100vw" }} className="supportMain">
        <div className="supportContainer">
          <div className="flex-container">
            <div
              className="flex-child"
              style={{ flexDirection: "column", display: "flex" ,marginRight:0 }}
            >
              {/* <h1
                className="page-title"
                style={{
                  marginLeft: "4%",
                  color: "#366ccc",
                  fontWeight: "800",
                  marginTop: "1%",
                  paddingBottom: "0.5rem",
                  fontSize: "3rem",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Support
              </h1>
              <hr /> */}
              {supportData ? (
                <div >
                  <div className="supportElement" dangerouslySetInnerHTML={{ __html: supportData.html }} />
                </div>
              ): <h3 style={{color:"grey", opacity:"0.6"}}>No support data available at the moment! Please try again after sometime.</h3>}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SupportScreen;
