export function getLocalStream() {
    return navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
            window.localStream = stream;
            //Request permission for notifications
            return Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted');
                } else {
                    console.error('Notification permission denied');
                }
            });
        })
        .catch((err) => {
            console.error(`you got an error: ${err}`);
        });
}

export function revokePermissions() {
    // Stop all tracks in the localStream
    if (window.localStream) {
        window.localStream.getTracks().forEach(track => {
            track.stop();
        });
        window.localStream = null;
    }
}
