// This is a spinner component

import React from "react";
import Spinner from "react-bootstrap/Spinner";
import ccsingle from "../images/ccsingle.png"
function Loading() {
  return (
      <>
      <div>
        <div className="loading-splash-container">
          <img src={ccsingle} alt="cc logo" style={{maxWidth:"250px"}}/>
          <Spinner className="custom-spinner" animation="border" style={{ width: "3rem", height: "3rem" }} />
        </div>
      </div>
      </>
    
    )
}

export default Loading;
