// Navbar component

import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import { IoIosMenu } from "react-icons/io";
import { logout, toggleSideBar } from "../actions/accountActions";
import { useNavigate } from "react-router-dom";
import carecoachlogo from "../images/logo.png";
import { Image } from "react-bootstrap";
import {ArrowExitFilled,ListBarFilled} from '@fluentui/react-icons'

import { DefaultButton,PrimaryButton } from '@fluentui/react';
import { FluentButton } from "./FluentComponents";

function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const callback = (response) => {
    if (response && response.status === 200) {
      navigate("/");
    }
  };

  const handleLogout = () => {
    dispatch(logout(callback));
  };


  return (
    <Navbar
      expand="lg"
      className="navbar navbar-light"
      style={{
        backgroundColor: "#3366CC",
        boxShadow: "0 7px 6px -6px #414141",
        position:"sticky",
        top:0,
        width:"100%",
        zIndex:4
        
      }}
      data-testid="navbar"
    >
      <Container style={{display:"flex", margin:"0"}}>
        <Navbar.Brand
          style={{
            color: "#FFF",
          }}
        >
          
    
          <Image
            src={carecoachlogo}
            style={{
              height: "2rem",
              paddingRight: "1rem",
            }}
          />
          Portal
          
        </Navbar.Brand>
        <div style={{display:"flex"}}>
          <ArrowExitFilled onClick={handleLogout} style={{fontSize:"30px"}} className='logoutIcon'/>
            {userInfo ? (
              <FluentButton  onClick={handleLogout} className="fluentButton">
              Log Out
            </FluentButton>
              
            ) : null}
            
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
