/**
 * Link Screen
 * Component for Link Page
 *
 * Pulls up Link to display from feature configuration picked up from localStorage
 */
import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

function LinkScreen(props) {
  const [links, setLinks] = useState([]);
  const [config,setConfig]= useState({});
  const [position,setPosition]=useState("");
  // user state
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo
  const user = userInfo; //get user from global state
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo){
      if (props.entry.config){
        setConfig(props.entry.config)
      }
      if (props.entry.config.links!=null || props.entry.config.links!=""){
        // Set the links once when the component mounts
        setLinks(props.entry.config.links);
        if (props.entry.config.position && props.entry.config.position!=""){
          setPosition(props.entry.config.position)
        }
      }
    }else{
      navigate("/")
    }
  }, [props.entry]); // Ensure this effect runs only when props.config.links changes

  ////this function is used to get the Links config in that
  // const GetLinks = () => {
  //   try {
  //     user.features.forEach((entry) => {
  //       if (entry.feature.config !== null) {
  //         if (entry.config.redirectTo == "/link") {
  //           setLinks(entry.config.links);
  //         }
  //       }
  //     });
  //   } catch {
  //     setLinks(null);
  //   }
  // };

  // // Run only once on component mount
  // useEffect(() => {
  //   GetLinks();
  // }, []);

  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100vw" }} className="linksMain">
        <div className="linksContainer">
          <div className="flex-container">
            <div className="flex-child">
              {/* <h1
                className="page-title"
                style={{
                  marginLeft: "4%",
                  color: "#366ccc",
                  fontWeight: "800",
                  marginTop: "1%",
                  paddingBottom: "0.5rem",
                  fontSize: "3rem",
                  width: "100%",
                  textAlign: "left",
                }}
              >
              {config && config.title && config.title != "" ? config.title : "Links"}
              </h1>
              <hr /> */}
              {/* checking for the links that its empty or not */}
              {links && links.length > 0 ? (
                <div
                  className="item-center justify-center width-full "
                  style={{ flexDirection: "column", height: position === 'center' ? '100vh' : 'auto' }}
                >
                  {/* Render the links one by one */}
                  {links.map(
                    (link, index) =>
                      link.url &&
                      link.url !== "" &&
                      link.title &&
                      link.title !== "" && (
                        <div key={index}>
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {/* Render link title within a button */}
                            <button
                              className="btn btn-outline-primary mt-2 link"//link-show class removed
                              title={link.title}
                            >
                              {link.title}
                            </button>
                          </a>
                        </div>
                      )
                  )}
                  {/* Check if not any valid links were rendered */}
                  {links.filter(
                    (link) =>
                      link.url &&
                      link.url !== "" &&
                      link.title &&
                      link.title !== ""
                  ).length === 0 && (
                    <div className="item-center justify-center">
                      {/* Display error message when no valid links are found */}
                      <h3 style={{color:"grey", opacity:"0.6"}}>
                        Can't get the links now. Please try again after some
                        time.
                      </h3>
                    </div>
                  )}
                </div>
              ) : (
                <div className="item-center justify-center">
                  {/* Display error message when the embedcode not found */}
                  <h3 style={{color:"grey", opacity:"0.6"}}>
                    Can't get the links now. Please try again after some time.
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default LinkScreen;
