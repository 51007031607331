// Microsoft login button component

import React from "react";
import { Button, Image } from "react-bootstrap";
import msal from "../images/msal.png";

function MicrosoftLoginButton({ handleLogin }) {
  return (
    <Button className="button microsoft microsoftButton" onClick={handleLogin}>
      <Image src={msal} className="msal-image" /> Sign in with Microsoft
    </Button>
  );
}

export default MicrosoftLoginButton;
